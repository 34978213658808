.title__news-title
    .h2__title
        font(Rb, 20px) 
.h1__title-adapt480
    +adapt(480px) 
       font-size 27px  
.title__news--item
    display flex
    height 150px
    margin-bottom 30px
    +adapt(768px)
        display block
        height auto
    &:last-child
        margin-bottom 0
.title__news-pic
    width 268px
    height 150px
    margin-right 20px
    +adapt(768px)
        width 100%
.title__news-pic-link
    display block
    width 100%
    height 100%
.title__news-img
    display block
    max-width 100%
    max-height 100%
.title__news-content
    width calc(100% - 268px)
    +adapt(768px)
        width 100%
        margin-top 10px
.title__news--title-link
    font(Rb, 18px)
    transition color .3s
    &:hover
        color blue
.title__news--text
    font(Rr, 16px)
    margin-top 10px