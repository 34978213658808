.block__products__grid
    width 100%
    height auto
    margin-top 20px
    margin-bottom 50px
.products__grid--list
    width 100%
    display flex
    justify-content space-around
    flex-wrap wrap
.products__list-slick
    position relative
    width 70%
    margin 0 auto
    +adapt(992px)
        width 80%
.products__grid--item
    width 210px
    height auto
    margin-bottom 20px
    margin-left 5px
    position relative

.products__grid__image--link
    display flex 
    justify-content center
    align-items center
    width 100%
    height 280px
    border 1px solid #d1d1d1
.product__image
    display block
    max-width 100%
    max-height 260px
.products__grid__description
    width 100%  
.products__grid__title--link    
    font(Rm, 19px)
    tran(color)
    &:hover
        color blue
.products__grid__price
    margin-top 2px
    
.products__grid__count
    font(Rm,16px, blue)
.products__grid-options
    width 100%
    display flex 
    justify-content space-between
    margin-top 2px
.add-cart--link
    font(Rm,16px)
    transition color ease .3s
    &:hover
        color blue
        .add-cart--svg
            fill blue
        
.add-cart--svg
    fill dark 
    width 16px
    height 16px
    transition fill ease .3s
.add-cart--shrt_text
    padding-left 5px
.add-wishlist--svg,
.magnifier--svg
    fill dark 
    width 16px
    height 16px
    transition fill ease .3s
    cursor pointer
    &:hover
        fill blue 

.add-wishlist--svg-active
    fill #ce424b
    &:hover
        fill #ce424b
.magnifier--svg
    width 22px
    height 19px
.products__grid--sale-circle
    &:after
        position absolute
        top 15px
        left 10px
        content "Скидка"
        width 55px
        height 55px
        background-color rgb(235,87,87)
        z-index 10
        border-radius 50%
        font(Rr, 10px, #fff)
        display flex
        justify-content center 
        align-items center
        text-transform uppercase
    .products__grid__price
        display flex
        justify-content flex-start
        .products__grid__count
            color rgb(235,87,87)
            text-decoration line-through
        .products__grid__count-discount
            font(Rm,16px, blue)
            margin-left 10px
.add-magnifier--link
    +adapt(992px)
        display none
.products__grid-warhouse
    width 100%
    display flex 
    justify-content center
    margin-top 2px
.products__grid-none
    font(Rm, 16px, #73726f)
.products__grid-single
    font(Rm, 16px, #e39236)
.products__grid-have
    font(Rm, 16px, blue)