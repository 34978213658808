adapt(num) 
   @media screen and (max-width: num)
        {block}

font(f=Rr,s=16px,c=dark)
    font-family f
    font-size s
    color c
tran(property=all, time=.2s, anim=ease)
    transition property anim time
    