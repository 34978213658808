.products
    display flex
    margin-top 30px
    position relative
    +adapt(992px)
        flex-direction column
        justify-contetn center
        margin-top 10px
.products-left
    width 24%
    height auto
    margin-right 2%
    transition left 0.5s, right 0.5s 
    padding-bottom 40px
    +adapt(992px) 
        position absolute
        left -120%
        background-color white
        box-shadow 0px 0px 7px 1px rgba(0,0,0,0.75)
        width 50%
        z-index 20
    +adapt(768px)
        width 70%
    +adapt(480px)
        width 100%
.products-right
    position relative
    width 74%
    +adapt(992px)
        width 100% 
.products-left--show
    left 0
    .headline,
    .filter-item,
    .popular-item
        padding-left 20px

    
