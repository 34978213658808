.cart_hover
    width 500px
    min-height 180px
    max-height 400px
    position absolute
    z-index 20
    overflow auto
    border 1px solid #e0e0e0
    right 5%
    background-color #fff
    display none
    +adapt(520px)
        width 400px

.cart_hover--list
    margin 0 auto
    width 90%
.cart_hover--item
    width 100%
    min-height 100px
    height auto
    display  flex
    align-items center
    border-bottom 1px solid #f7f7f7
    
.cart_hover--pic
    width 15% 
    height 70px
.cart_hover--img 
    display block
    max-width 100%
    height 100%

.wrap_product
    width 85%
    height 70px
    padding-left 20px
    
.cart_hover--descr
    height 100%
    width 100%
.title__product--link 
    font(Rr, 16px)
    transition color ease .3s
    &:hover
        color blue
.cart__price
    font(Rr, 15px, blue)
.cart__count
    font(Rr, 15px, dark)
.cart__remove
    width 100%
    text-align right 
.remove_svg 
    width 15px
    height 15px
    fill blue
    transition fill .3s
    cursor pointer
    margin-right 10px
    &:hover
        fill dark
.cart_hover_footer
    width 100%
    height 70px
    border-top 1px solid #f7f7f7
    background-color #fff
    
.wrap__footer
    display flex
    align-items center
    justify-content space-between
    width 90%
    height 100%
    margin 0 auto
.price__text
    font(Rb, 16px)
.price__count
    font(Rb, 18px, blue)
