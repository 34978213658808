.popup__product
    position fixed 
    top 0
    left 0
    right 0
    bottom 0
    background-color rgba(56,56,56,0.9)
    z-index -50
    opacity 0
    
.popup__product--active
    z-index 50
    opacity 1
    .popup__product__card
       opacity 1 
.popup__product__card
    tran(opacity, 0.3s)
    opacity 0 
    position absolute
    width 800px
    height 460px
    top 50%
    left 50%
    transform translate(-50%, -50%)
    background-color #fff
    display flex 
    justify-content center 
    align-items center
   
    +adapt(810px)
        width 97%   
.popup__product--content
    width 720px
    height 360px
    overflow-x: auto;
    white-space: nowrap;
    display flex
    margin-left 30px
    margin-right 30px
.popup__product__image
    width 360px
    height 100%
    display flex 
    justify-content center 
    margin-right 20px 
 
.wrap__popup-image
    width 250px
    height 350px
    
.popup-image
    max-width 100%
.popup__product__description
    width 360px
    height 100%

.popup__product--title-link
    font(Rm, 22px)
    tran(color)
    &:hover
        color blue
.popup__product--availability
    font(Rr, 16px)
    margin-top 5px
.popup__product--availability__prop
    font(Rm, 16px)
.popup__product--price
    font(Rr, 24px)
.popup-discount
    display flex
    align-items flex-end
    .popup__product--price-count
        font(Rr, 18px)
        text-decoration line-through
        height 19px
.popup__product--price-discount 
    margin-left 8px
    height 26px
.popup__product--text
    font(Rr, 16px)
    width 100%
    white-space normal
.block__button--popup__product
    margin-top 40px
.popup__product__add_button
    height 45px
    width 210px
    text-transform uppercase
    font(Rb, 16px, #fff)
.popup__product--closed
    position absolute
    width 19px
    height 19px
    fill blue
    top 5px
    right 5px
    cursor pointer
    tran(fill)
    &:hover
        fill dark
.popup__product--quantity
   display flex
.quantity-shrt_text
    font(Rr, 16px)
.popup__product--plus
    border 1px solid #dddddd
    display flex 
    justify-content center
    align-items center
    width 28px
    height 28px
    margin-left 10px
    cursor pointer
    &:hover
        .popup__product__plus--svg
            fill blue
        
.popup__product--minus
    border 1px solid #dddddd
    width 28px
    height 28px
    display flex 
    justify-content center
    align-items center
    cursor pointer
    &:hover
        .popup__product__minus--svg
            fill blue

.popup__product__minus--svg
    width 15px
    height 15px
    fill dark
    tran(fill)
.popup__product__plus--svg
    width 15px
    height 15px
    fill dark
    tran(fill)
.popup__product--input
    width 28px
    height 28pxx
    border 1px solid #dddddd
    &[value]
        text-align center
        padding-top 3px
    &:focus 
        outline none
        
.know__more
    margin-top 10px
.know__more--link
    tran(color)
    font(Rm,16px)
    &:hover 
        color blue