.footer
    width 100%
    height auto
    border-top 2px solid #e8e8e8
    margin-top 30px
    padding-top 30px
.footer__options
    width 100%
    height auto
.footer__options--list
    width 100%
    display flex
    justify-content space-between
    +adapt(910px)
        flex-wrap wrap
    +adapt(480px)
        justify-content center
.footer__options--item
    width 23%
    background-color white
    padding 20px
    +adapt(910px)
        width 48%
        margin-bottom 20px
    +adapt(480px)
        width 100%
.footer__title
    font(Rm, 16px)
.footer__company
    font(Rb, 20px)
    margin-top 5px
.footer__descr
    font(Rr, 16px)
    margin-top 10px
.footer__options--sub-items
    margin-top 10px
    +adapt(910px)
        margin-bottom 20px
.footer__options--sub-link
    font(Rr, 16px)
    &:hover
        tran(color)
        color blue
.footer__bottom
    width 100%
    height 60px
.footer__information
    width 100%
    height 100%
    display flex
    justify-content center
    align-items center
    font(Rr, 16px)
    +adapt(345px)
        flex-direction column
.footer__information--link
    font(Rr, 16px)