.wrapper__card-product
    width 100%
    display flex
    margin-top 20px
    +adapt(768px)
        display block
.card-product--left
    width 40%
    margin-right 30px
    +adapt(768px)
        width 100%
        text-align center
.card-product--right
    width 60% 
    white-space wrap
    +adapt(768px)
        width 100%
        margin-top 20px
.wrap-pic
    width 100%
.wrap-more-pic
    display flex
    flex-wrap wrap
    width 100%
    margin-top 20px
    +adapt(768px)
        justify-content center
.item-more--img
    width 180px
    height 250px
    margin-right 10px
    cursor pointer
    &:last-child
        margin-left 0
.card-product-img,
.card-product-more-img
    display inline-block
    max-width 100%
.item-more--img
    width 80px
    height 120px
.card-product--right
    +adapt(480px)
        .h1__title
            text-align center
.card-product-value
    font(Rr, 16px)
    margin-left 10px
.card-product-key
    font(Rm, 16px)
.card-product-button
    width 250px
    text-transform uppercase
    font(Rm, 16px, #fff)
    +adapt(855px)
        width 200px
    +adapt(768px)
        width 250px
.not-active
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: #73726f
    background #c9c6bd
    width 100%
    height 37px
    display flex
    align-items center
    justify-content center
    padding 0 10px
.card-product--size,
.card-product--descr,
.warhouse_count_products
    margin-top 10px
.card-product--block-wishlist
    display flex
    align-items center
    margin-left 20px
    font(Rm, 16px)
    cursor pointer
    width 200px
    transition color .3s
    +adapt(524px)
        margin-top 30px
        margin-bottom 20px
    &:hover
        color blue
        .card-product--block-wishlist-svg
            fill blue
.card-product--block-wishlist-active
    color #ce424b
    &:hover
        color #ce424b
        .card-product--block-wishlist-svg
            fill #ce424b

.card-product--block-wishlist-svg
    width 20px
    height 20px
    fill dark
    margin-right 5px
    transition fill .3s
.card-wrap__btns
    display flex
    align-items center
    flex-wrap wrap
    height 44px
    margin-top 20px
    +adapt(524px)
        flex-direction column
        justify-content center
        align-items center
        flex-wrap nowrap
        margin-top 40px
        margin-bottom 40px
.card-product--price
    display flex
    align-items center
    margin-top 10px
.card-product--current
    font(Rb, 22px)
    margin-right 10px
.card-product--sale
    font(Rm, 18px, #eb5757)
    text-decoration line-through