.header
    width 100%
    min-height 80px
    height auto
.header__top 
    width 100% 
    background-color blue
    min-height 40px
    +adapt(320px)
        min-width 320px
.container__header__top
    display flex 
    align-items center
    min-height 40px
    height auto
    width 100%
.header__social--list
    display flex    
.header__social--item,
.header__social--list
    height 27px
.header__social--item
    margin-left 7px

    &:first-child
        margin-left 0

.header__social--svg
    width 25px
    height 25px
    fill white
    transition all ease .3s
.header__options--svg
    width 16px
    height 16px
    fill white
    transition all ease .3s
    +adapt(380px)
        width 22px
        height 22px
.header__options
    margin-left auto
.header__options--list
    display flex 
    height 25px
.header__options--item
    height 25px
    margin-left 10px

    &:first-child
        margin-left 0
    
.header__options--link,
.header__options--auth_link
    display flex
    align-items center
    font(Rb, 16px, white)
    text-decoration none
.header__options--svg
    margin-right 5px

.header__social--link
    &:hover
        .header__social--svg 
            fill dark
.header__options--link
    transition all ease .5s
    &:hover
        color dark
        .header__options--svg 
            fill dark

.header__bottom
    width 100%
    min-height 70px
    height auto
    border-bottom 2px solid rgba(242,242,242,1);
    
    +adapt(320px)
        min-width 320px
.container__header__bottom
    display flex
    justify-content space-between
    align-items center
    min-height 70px
    height auto
    +adapt(992px)
        justify-content flex-start
    +adapt(600px)
        flex-wrap wrap
.header__logo
    +adapt(600px)
        width 100% 
        margin-top 10px
.home__link
    display flex
    align-items center
    height 50px
    text-decoration none
.name-shop
    margin-left 10px
    font(Rb, 20px)
.header__cart
    position relative
    &:hover
        .cart_hover 
            display block
            +adapt(450px)
                display none       
    +adapt(992px)
        margin-left auto
.header__cart--link
    display flex 
    align-items center
    text-decoration none
    font(Rr, 15px)
    transition color ease .3s
.header__cart--svg
    width 20px
    height 20px
    fill blue
.header__cart--text
    font(Rb, 14px, blue)
    text-transform uppercase
.header__menu
    +adapt(992px)
        display none
.header__menu--list
    display flex
    align-items center 
.header__menu--item 
    margin-left 10px
    position relative
    &:first-child
        margin-left 0
    &.header__menu--item-active
        .header__menu--link
            color blue
    &:hover
        .header__submenu--list
            display block 

.header__menu--link
    font(Rb, 13px)
    text-transform uppercase
    transition color ease .3s
    &:hover
        color blue
        .header__submenu--list
            display block
.header__submenu--list
    display none
    position absolute
    width 230px
    height auto
    box-shadow 0px 2px 7px -1px rgba(0,0,0,0.67)
    background-color white
    border-radius 5px
    z-index 30
.header__submenu--item
    width 80%
    margin 10px auto
    &:first-child
        margin-top 20px
    &:last-child
        margin-bottom 20px
.header__submenu--link
    font(Rr,15px)
    transition color ease .3s
    &:hover
        color blue
.header__options--text,
.header__options--auth_name
    +adapt(380px)
        display none
.btn__mobile
    display none
    +adapt(992px)
        display block
        position relative
        margin-left 50px
        cursor pointer
        &::before
            position absolute
            content "Меню"
            left 30px
            top 50%
            transform translateY(-50%)
            font(Rm, 17px)
    +adapt(650px)
        margin-left 25px
    +adapt(600px)
        margin-top 10px
        margin-bottom 10px
        margin-left 50px
     +adapt(380px)
        margin-left 20px

.btn__mobile-line
    width 25px
    height 3px
    border-radius 5px
    background-color dark
    margin-top 3px

    &:first-child
        margin-top 0       
.header__scroll-fixed
    position fixed
    top 0
    z-index 30
    background-color #fff
    .header__top
        display none
