.block__completion-inform,
.block__completion-header
    margin-top 20px 
.block__completion-inform
    width 95%
.inform-list
    width 100%
    border 1px solid #d8d8d8
    border-radius 4px
    display flex
    flex-direction column
    align-items center
.inform-item
    width 97%
    border-bottom 1px solid #d8d8d8
    min-height 40px
    height auto
    display flex
    align-items center
    &:last-child
         border-bottom none
    +adapt(550px)
        display block
.inform-item-key
    font(Rm, 17px)
    width 24%
    margin-right 2%
    +adapt(550px)
        width 100%
        margin-right 0
.inform-item-value
    font(Rr, 16px)
    width 74%
    +adapt(550px)
        width 100%
        margin-top 5px