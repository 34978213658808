.tabs
    margin-top 30px
.tabs-list
    display flex
    width 100%
    +adapt(480px)
        justify-content center
.tabs-item
    margin-left 10px
    width 170px
    height 37px
    font(Rb,16px)
    transition all ease .4s
    cursor pointer
    text-transform uppercase
    display flex 
    align-items center 
    justify-content center
    border: 2px solid #fff
    &:hover
        border: 2px solid blue
    &:first-child
        margin-left 0
    +adapt(480px)
        margin-left 5px
.tabs-item-active
    background-color blue
    color #fff
.tabs__content
    margin-top 20px