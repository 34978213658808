.confirm-right
    width 40%
    min-height 100vh
    height auto
    background-color rgb(250,250,250)
    padding-top 50px
    padding-right 50px
    padding-left 40px
    border-left 1px solid #d8d8d8
    +adapt(1100px)
        padding-left 15px
        padding-right 20px
    +adapt(992px)
        display none
        order 1
        padding-top 0
        padding-left 16px
        padding-right 16px
        width 100%
        min-height 0
        margin 0 auto
.orders__cart
    width 100%
    max-height 560px
    height auto
    position relative
.orders__cart__product-grid
    width 100%
    height auto
.orders__product--list
    width 100%
    max-height 390px
    height auto  
    overflow auto
.orders__product--item
    width 100%
    display flex 
    align-items center
    justify-content space-between
    margin-top 10px
.orders__product--pic
    width 56px
    height 70px
    position relative
.orders__product-img
    display block
    max-width 100%
    border-radius 10px
    overflow hidden
.wrap__orders__count
    position absolute
    top -3px
    right -4px
    width 23px
    height 23px
    border-radius 50%
    background-color rgb(128,128,128);
    display flex
    align-items center
    justify-content center
    font(Rr, 16px, rgb(255,255,255))
.orders__product--desc
    width 70%
    text-align center
    font(Rr, 16px)
.orders__product--total
    width 30%
    text-align center
    font(Rr, 16px)
.order__total
    height 150px
    width 100%
    margin-top 20px
.wrap__sub
    width 100%
    height 65%
    display flex 
    flex-direction column
    justify-content center
    border-top 1px solid #d8d8d8
    border-bottom 1px solid #d8d8d8
.wrap__order
    width 100%
    height 35%
    display flex 
    flex-direction column
    justify-content center
.wrap__sub-total
    width 100%
    display flex
    justify-content space-between
.order__total-price
    width 100%
    display flex
    justify-content space-between