.section__pagination
    position absolute
    bottom 0
    display flex
    justify-content flex-end
    align-items center
    border-top 1px solid dark
    padding-top 15px
    +adapt(480px)
        justify-content center
.pagination-list
    display flex
    height 30px
.pagination-hide-mobile
    +adapt(480px)
        display none
.pagination-item
    width 30px
    height 100%
    border-right 1px solid dark
    border-bottom 1px solid dark
    border-top 1px solid dark
    &:first-child
        border-left 1px solid dark
        border-top-left-radius 4px
        border-bottom-left-radius 4px
    &:last-child
        border-top-right-radius 4px
        border-bottom-right-radius 4px

.pagination-link,
.pagination-link--prev,
.pagination-link--next
    display block
    width 100%
    height 100%
    color dark
    display flex
    justify-content center
    align-items center
    tran(all, 0.3s)
    font(Rr)
    &:hover
        color #ffffff
        background-color dark
        .pagination-link-next-svg,
        .pagination-link-prev-svg
            fill #ffffff
.pagination-item-active 
    .pagination-link
        color #ffffff
        background-color dark
.pagination-item-prev,
.pagination-item-next
    width 80px
    height 30px
    border-radius 4px
    border 1px solid dark
.pagination-item-prev
    margin-right 10px
.pagination-item-next
    margin-left 10px
.pagination-link-next-svg,
.pagination-link-prev-svg
    width 12px
    height 12px
    fill dark
    tran(fill, 0.3s)
.pagination-link-prev-svg
    margin-right 5px
.pagination-link-next-svg
    margin-left 5px
.pagination-item-mobile
    display none
    width 80px
    height 100%
    +adapt(480px)
        display flex
        justify-content space-around
        align-items center
.pagination-item-mobile-shtrtext
    font()
.pagination-item-mobile-current,
.pagination-item-mobile-total
    font(Rm, 17px)