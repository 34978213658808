@font-face {
    font-family: 'Rb';
    src: url('../fonts/roboto-bold-webfont.woff2') format('woff2'),
         url('../fonts/roboto-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Rl';
    src: url('../fonts/roboto-light-webfont.woff2') format('woff2'),
         url('../fonts/roboto-light-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;

}

@font-face {
    font-family: 'Rm';
    src: url('../fonts/roboto-medium-webfont.woff2') format('woff2'),
         url('../fonts/roboto-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;

}

@font-face {
    font-family: 'Rr';
    src: url('../fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('../fonts/roboto-regular-webfont.woff') format('woff');
     font-weight: 400;
    font-style: normal;

}