.block__menu__mobile
    +adapt(992px)
        overflow: hidden;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        transition: all ease .3s;
        opacity: 0;
        z-index -10    
.block__left
    +adapt(992px)
        position absolute
        left 0
        top 0
        bottom 0
        width 0
        overflow hidden
        background-color white
        transition all ease .3s
.menu__mobile--list
    width 87%
    margin 30px auto
.menu__mobile--item
    width 100%
    height 45px
    height auto
    border-bottom 2px solid rgb(217,217,217)
    &:last-child
        border-bottom none
.wrap__link-plus
    width 100%
    height 45px
    display flex
.menu__mobile--item-active
    .menu__mobile--link
        color blue
.menu__mobile--link
    width 92%
    height 45px
    display flex
    align-items center 
    font(Rm, 18px, rgb(79, 79, 79))
    transition color 0.3s
    &:hover
        color blue
.btn__plus
    margin-right auto
    cursor pointer
    width 8%
    height 100%
    display flex
    justify-content center
    align-items center
.wrap__plus
    height 5px
.btn__plus--line1,
.btn__plus--line2
    width 25px
    height 4px
    background-color  rgb(137, 137, 137)

    border-radius 5px
    transition 0.4s
    
.btn__plus--line2
    transition 0.3s
    transform rotate(-90deg) translateY(0px) translateX(4px)
.btn__plus--active
    .btn__plus--line2
        transform rotateY(-90deg) rotateX(-90deg)
        

.header__mobile__submenu--list
    display none
    width 100%
.header__mobile__submenu--item
    width 100%
    height 37px
    border-top 1px solid rgb(217,217,217)
.header__mobile__submenu--link
    padding-left 20px
    width 100%
    height 100%
    transition color ease .3s
    font(Rr,16px)
    display flex 
    align-items center
    &:hover
        color blue
.block__right
    +adapt(992px)
        position absolute
        right 0
        width 0
        height 100%
        overflow hidden
        transition all ease .3s
        background-color rgba(56,56,56,0.9)
.closed
    position absolute 
    top 50%
    left 50%
    transform translate(-50%, -50%)
.closed-svg
    fill white
    width 30px
    height 30px
    cursor pointer
.block__menu__mobile--active
    z-index 10
    opacity 1
    .block__right
        width 30%
        overflow visible
    .block__left
        width 70%
        overflow visible
.closed
    display none 
    +adapt(992px)
        display block
.menu__mobile--list
    display none 
    +adapt(992px)
        display block