.cart
    width 100%
    height auto
    margin 20px auto
.cart__header
    width 100%
    height auto 
    display flex 
    padding-bottom 5px 
    border-bottom  2px solid #d8d8d8
    font(Rm,16px)
    +adapt(480px)
        display none
.cart__header--product-pic
    width 110px
    text-align center
.cart__header--options
    width 100%
    text-align center
.cart__body
    width 100%
    margin-top 10px
.cart__body__box
    width 100%
    display flex 
    padding-bottom 10px
    margin-bottom 10px
    border-bottom 1px solid #d8d8d8
.cart__body--pic
    width 100px
.cart__body--options
    text-align center
    width 100%
    position relative
.cart__body--title-link
    font(Rl, 22px)
    tran(color)
    &:hover
        color blue
    +adapt(480px)
        font-size 18px
.wrap__price
    width 100%
    display flex 
    justify-content center
    margin-top 20px
    +adapt(480px)
        display block
        margin-top 0
.cart__body--price
    width 15%
    text-align center
    font(Rm, 16px, blue)
    +adapt(768px)
        width 30%
    +adapt(480px)
        width 100%
        margin-top 5px
.cart__body--total
    min-width 15%
    width auto
    text-align center
    font(Rm, 16px, blue)
    +adapt(768px)
        width 30%
    +adapt(480px)
        width 100%
        margin-top 5px
.cart__body--quantity
    width 25%
    +adapt(768px)
        width 40%
    +adapt(480px)
        width 100%
        margin-top 5px
.cart__body--remove
    position absolute
    bottom 0
    right 0

.remove-svg
    width 15px
    height 15px
    cursor pointer
    fill dark
.body--quantity-center
    display flex 
    justify-content center
       
.cart__product--plus
    border 1px solid #dddddd
    display flex 
    justify-content center
    align-items center
    width 28px
    height 28px
    cursor pointer
    &:hover
        .popup__product__plus--svg
            fill blue
        
.cart__product--minus
    border 1px solid #dddddd
    width 28px
    height 28px
    display flex 
    justify-content center
    align-items center
    cursor pointer
    &:hover
        .popup__product__minus--svg
            fill blue
.cart__product--input
    width 28px
    height 28pxx
    border 1px solid #dddddd
    &[value]
        text-align center
        padding-top 3px
    &:focus 
        outline none

.cart__remove-svg
    width 20px
    height 20px
    cursor pointer
    fill #e84e56
    tran(fill)
    &:hover
        fill dark
.cart__block-button
    width 100%
    display flex 
    justify-content space-between
    +adapt(540px)
       justify-content center 
       flex-direction column
       align-items center 
.cart-continue-shopping--button
    width 190px
    +adapt(540px)
        order 2
        margin-top 15px
.cart-next--button
    background-color dark
    tran(background-color, blue)
    border none 
    outline none 
    &:focus
       outline none 
    &:hover
       background-color blue
    +adapt(540px)
        order 1