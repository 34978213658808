.filter,
.form__filter,
.filter-item,
.form-label
    width 100%
.form__filter
    margin-top 20px
.filter-item
    min-height 30px
    height auto
    margin-top 10px
    &:first-child
        margin-top 0
.form-label
    display flex
    align-items center
    cursor pointer
.form-text
    font(Rr, 16px)
    margin-left 10px
.checkbox-custom
    position relative
    height 23px 
    width 23px             
    border 2px solid blue
    border-radius 3px
	
.filter-input
    display none
    &:checked + .checkbox-custom
        &:before
            content "\2714"   
            display block			
            position absolute      
            top 50%
            left 50%
            transform translate(-50%, -50%)
            border-radius 1px
            color blue
            font-weight 500 
.filter__button-box
    display flex
    flex-direction column
    align-items  center 
                
.filter__button
    width 100%
    border none
    outline none 
    display inline-block
    margin-top 20px
    margin-bottom 20px
    &:focus
        border none
        outline none
.filter-reset
    font(Rr, 16px)
    tran(color)
    &:hover
        color #d34747
.filter-hide
    display none
    font(Rr, 16px, #d34747)
    tran(color)
    margin-top 20px
    +adapt(992px) 
        display block
    &:hover
        font(Rr, 16px)
    
.advertising,
.popular
    width 100%
    +adapt(992px)
        display none
.advertising-link 
    width 100%
    display block
    text-decoration none
.advertising-img,
.popular-img
    display block
    max-width 100%
.popular-list
    width 100%
    margin-top 20px   
.popular-item
    width 100%
    display flex
    margin-top 30px
    &:first-child
        margin-top 0  
.popular-descr
    width calc(100% - 100px)
    margin-left 10px
.popular-pic
    width 100px
.popular-pic-link
    display block
    width 100% 
.advertising,
.filter,
.popular
    margin-top 30px
.popular-price
    display flex
    color blue
    margin-top 5px
.popular-price-sale//  по умолчанию. скрыт при наличии класса have-sale показывается (этот класс навешивается при наличии скидки)
    margin-left 10px
    display none
.have-sale
    .popular-price-sale
        display: block
    .popular-price-current
        text-decoration line-through
.popular-link
    color dark
    tran(color)
    &:hover
        color blue
.popular-button-wrap
    margin-top 15px
.popular-button
    width 100%
    height 35px