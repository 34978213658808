.article
    width 100%
    font(Rr, 16px)
    h1
        font(Rb, 25px)
        padding-bottom 20px
.wrap__frame
    width 100%
    display flex
    justify-content: center
.style__video
    width: 560px;
    height: 300px;
    width: 560px;
    height: 300px;
    +adapt(874px) 
        width: 460px;
        height: 250px;

    +adapt(600px) 
        width: 400px;
        height: 250px;
    
    +adapt(480px) 
        width: 290px
        height: 200px
    