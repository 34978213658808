.cart__discount
    width 100%
    height auto 
    margin-bottom 40px
    display flex 
    +adapt(768px)
        display block
.cart__discount--left,
.cart__discount--right
    width 50%
    height auto 
    +adapt(768px)
        width 100%
.cart__discount--left
    border-right 2px solid #d8d8d8
    +adapt(768px)
        border-right none
.form__promocode
    width 100%
    display flex
    margin-top 10px
    margin-bottom 10px
    +adapt(480px)
        display block
.promocode-input
    width 60%
    height 35px
    border 1px solid #d8d8d8
    outline none
    &:focus 
        border 1px solid dark
    +adapt(480px)
        width 95%
        text-align: center
.promocode__button
    width 30%
    height 35px
    margin-left 30px
    margin-right 30px
    border none
    outline none 
    &:focus
        border none
        outline none
    +adapt(480px)
        width 95%
        text-align: center
        margin-left 0
        margin-right 0
        margin-top 20px
.points__link
    font(Rr, 16px, blue)
.points__button
    width 100%
    margin-top 10px
.points__button--link
    display block
    width 260px
    height 40px
    font(Rr, 16px, blue)
    border 1px dashed #4f4f4f
    display flex 
    justify-content center 
    align-items center
    border-radius 5px
    tran(all)
    &:hover
        border 1px solid lighten(#4f4f4f, 80%)
        box-shadow: 0px 0px 5px -1px rgba(79,79,79,1);

.cart__discount--right
    padding-left 10px  
    position relative 
    +adapt(768px) 
        padding-left 0 
        min-height 80px
        height auto
.wrap__total
    width 100%
    display flex
    justify-content space-between
    margin-top 10px    
.wrap__total--bottom
    position relative
    bottom 0
.wrap__total--border
    padding-bottom 10px
    border-bottom 2px solid dark
.wrap__total--color
    color #e84e56
    