.block__title-text--about
    border-bottom 1px solid #d8d8d8
    padding-bottom 5px
    margin-top 15px
    +adapt(400px)
        text-align center
.block--shrt_text--about
    margin-top 15px
.about
    width 100%
.about__list
    width 100%
    display flex 
    justify-content space-between
    
    +adapt(1200px)
        display block
.about__list
    margin-top 35px
.wrap__descr
    display flex
    flex-direction column
    align-items center
    width 100%
    min-height 100px
    height auto
    +adapt(875px)
        width 80%
    +adapt(630px)
        width 100%      
.about__item
    width 32%
    min-height 200px
    height auto
    display flex
    flex-direction column
    align-items center
    +adapt(1200px)
        width 100%
        flex-direction row
        margin-bottom 20px
        &:last-child
            margin-bottom 0
    +adapt(630px)
        flex-direction column
        margin-bottom 30px
        &:last-child
            margin-bottom 0
    .block__sections--title
        font(Rr, 20px)
        margin-top 15px
    .block__title-simple-text
        margin-top 15px
.about__item-svg
    width 125px
    height 125px
    +adapt(1200px)
        margin-right 20px 
        width 125px
    +adapt(630px)
        margin-right 0 
.wrap-svg
    width 125px
    height 125px
    border-radius 50%
    border 3px solid black
    display flex 
    justify-content center
    align-items center
.about--svg
    width 80px
    height 80px
.team
    width 100%
    height auto 
    margin-top 30px
.about__item-img
    width 265px
    height 270px
    +adapt(875px)
        margin-right 20px 
        width 235px
        height 250px
        display flex 
        align-items center
.about-img
    max-width 100%
.about-cruew__link
    font(Rr, 16px, blue)