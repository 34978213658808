.navigation__cart
    margin-top 10px
    width 100%
    +adapt(992px)
        display none
.navigation__cart--chain
    width 100%
.navigation__cart__link
    font(Rr, 16px, dark);
.navigation__cart-svg
    fill dark 
    width 12px
    height 10px
.navigation__cart__page
    font(Rr, 16px, dark)
.navigation__cart__curent-page
    font(Rr, 18px, rgb(25,144,198))
    border-bottom 1px dashed rgb(25,144,198)
.navigation__cart--step
    font(Rr, 18px, dark)