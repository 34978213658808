.options__wishlist
    width 100%
    display flex 
    justify-content flex-end
    margin-top 5px
.wishlist__add-cart--link,
.wishlist__del-wishlist--link,
.wishlist__add-magnifier--link
    width 35px
    height 35px
    .add-wishlist--svg,
    .magnifier--svg,
    .add-cart--svg
        fill #fff
.wishlist__add-cart--link,
.wishlist__add-magnifier--link
    margin-right 10px
