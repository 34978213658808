.wrapper 
    width 100%
    min-height 600px
    height 100%
    position relative

.maincontent 
    height 100%
    position relative

.section 
    width 100%
    height auto
    position relative
.container 
    margin 0 auto
    max-width: 1200px
    padding-left 10px
    padding-right 10px 
    +adapt(1200px)
        max-width: 992px
        width 100% 
        
    +adapt(992px)
        max-width: 992px
        width 100% 
        
    +adapt(768px)
        max-width: 768px
        width 100% 
        
    +adapt(480px)
        max-width: 480px
        width 100% 
    +adapt(320px)
        min-width 320px
        
.block__title
    width 100%
.h1__title
    font(Rb, 32px)
.h2__title
    font(Rb, 27px)
.h3__title
   font(Rm, 18px)
.position-center
    text-align center
.position-center-top
    text-align center
    margin-top 10px
.margin-top
    margin-top 40px
.margin-bottom
    margin-bottom 20px
.button__link
    display block
    width 170px
    height 37px
    background-color blue
    font(Rr,16px, white)
    display flex 
    align-items center 
    justify-content center
    transition background-color ease .2s
    cursor pointer
    &:hover 
        background-color dark
.headline
    font(Rr, 18px)
.button-show-sidebar
    width 200px
    height 30px
    border 1px solid blue
    color blue
    text-align center
    cursor pointer
    border none
    outline none 
    background transparent
    border 1px solid blue
    border-radius 5px
    margin-top 10px
    tran(all)
    display none
    &:hover
        background-color blue
        color #fff;
    +adapt(992px)
        display block
.button-show-sidebar-active
    background-color blue
    color #fff;
button
    border none 
    outline none 
    background transparent
.message__error
    position absolute
    top 90%
    height 30px
    min-width 250px
    width auto
    display flex
    justify-content center
    align-items center
    background-color #d82f2f
    border-radius 4px
    font(Rr, 14px, #fff)
    z-index: 20
    opacity 0.5
    &:after
        content ""
        border-left 10px solid transparent
        border-right 10px solid transparent
        border-bottom 10px solid #d82f2f
        position absolute
        top -30%
        left 10%
.message__error-email-profile,
.message__error-phone-profile,
.message__error-index-profile,
.message__error-address-profile,
.message__error-patronymic-profile,
.message__error-lastname-profile,
.message__error-firstname-profile
    +adapt(950px)
        top 30%

.error__container
    width 100%
    display none  
.block__massage-forgot,
.block__massage-reg,
.block__massage-auth
    display none 
.error__items
    font(Rm, 16px, #f94545)
    margin-top 10px
    margin-bottom 20px
.default__masseg-error
    width 100%
    padding 15px 0px 15px 15px
    background-color #f7aba8
    border 1px solid #a05341
    font(Rr, 16px)
.wrap__btn__slider
    width 100%
    display flex
    justify-content center   