.section__account
    height auto
.container__account
    overflow hidden
.block__title--account 
    margin-top 20px
    text-align center
.h2__title--account
    font(Rr, 17px)
    text-transform uppercase
.block__account
    height auto
    margin-top 20px
    position relative
    

    // display none


.wrap__account
    width 100%
    display flex 
    justify-content space-between
    +adapt(992px)
        align-items center 
        flex-direction column
     
.account__create,
.account__auth
    width 48%
    border-top 2px solid black
    border-left 1px solid #d8d8d8
    border-right 1px solid #d8d8d8
    border-bottom 1px solid #d8d8d8
    padding 20px 20px 20px 20px 
    +adapt(992px)
        width 100%
    
.account__auth
    +adapt(992px)
        margin-top 30px
.block__title-text
    font(Rr, 21px)
.block--shrt_text
    font(Rr, 16px)
    margin-top 8px
.account__create--list
    margin-top 8px
    width 100%
.account__create--item
    font(Rr, 16px)
.account__create--mark
    font(Rb, 19px, black)
    padding-right 5px
.account__create--button
    margin-top 15px
.block__form,
.form__auth
    width 100%
    height auto
.auth__label
    width 100%
    cursor pointer
    position relative
.auth__input--email,
.auth__input--pass
    width 100%
    height 35px
    border 1px solid #d8d8d8
    outline none
    &:focus 
        border 1px solid dark
.auth--email,
.auth--pass,
.auth__button
    margin-top 20px
.forgot--link
    margin-left 10px
    font(Rr, 16px)
    tran(color)
    &:hover
        color blue
    +adapt(400px)
        display block
        margin-top 10px
.auth__input--button
    border none
    outline none
    display inline-block
    &:focus
        border none
        outline none
.registration__recaptcha
    width 100%
    margin-top 20px
    +adapt(480px)
        transform scale(0.75)
        transform-origin left top